import React from 'react'
import Layout from '../components/Layout'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import Spacing from '../components/partials/Spacing'

class video extends React.Component {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    instance: HTMLElement
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    instance2: HTMLElement

    componentDidMount() {
        const script = document.createElement('script')
        script.src = 'https://apis.google.com/js/platform.js'
        script.async = true

        this.instance.appendChild(script)

        const script2 = document.createElement('script')
        script2.src = 'https://static.addtoany.com/menu/page.js'
        script2.async = true
        this.instance2.appendChild(script2)
    }

    render() {
        const tempDate: any = get(this.props, 'data.contentfulVideo.date')
        const date = new Date(tempDate)
        const dateOptions = {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        } as const

        const video: any = get(this.props, 'data.contentfulVideo')

        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Layout>
                {/* {typeof location !== 'undefined' &&
        (location.pathname === '/videos/kafka-gitops-devops-webinar/' ||
          location.pathname === '/videos/kafka-gitops-devops-webinar') ? (
          <div className="home-banner">
            Going to KubeCon NA 2020? - Visit Lenses in Startup Hall B
            <a
              href="//events.linuxfoundation.org/kubecon-cloudnativecon-north-america/"
              className="ml-1 text-white mobile-padding-right-half mobile-margin-reset"
            >
              | Register Here &gt;
            </a>
          </div>
        ) : (
          ''
        )} */}
                <Helmet>
                    <title>{video?.seoTitle} | Lenses.io</title>
                    <meta name="twitter:text:title" content={video?.seoTitle} />
                    <meta name="description" content={video?.description} />
                    <meta property="og:description" content={video?.description} />
                    <meta property="twitter:description" content={video?.description} />

                    <meta name="keywords" content={video?.keywords.keywords} />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@lensesio" />
                    <meta property="og:title" content={video?.seoTitle} />
                    <meta property="twitter:title" content={video?.seoTitle} />
                    <meta property="og:image" content={'https:' + video?.oGimage.fluid.src} />
                    <meta property="twitter:image" content={'https:' + video?.oGimage.fluid.src} />
                </Helmet>
                {/* <img className="home-fixed-top" src={hero_lens} alt="Home Lense" /> */}
                <section className="mt-5 pt-5 pb-5">
                    <div className="container-1">
                        <div className="row mt-3">
                            <div className="col-md-6 pt-0">
                                <p className="title breadcrumb-text pt-4">
                                    <a href="/resources/" className="title_text">
                                        RESOURCES
                                    </a>{' '}
                                    /
                                    <a href="/videos/" className="title_text font-weight-bold">
                                        VIDEOS
                                    </a>
                                </p>
                                <h1>{video?.title}</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="summary" className="pt-3">
                    <div className="container-1">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="videoWrapper pt-5">
                                    <iframe
                                        title={`${video.title}`}
                                        width="560"
                                        height="300"
                                        src={`https://www.youtube.com/embed/${video.videoId}`}
                                        frameBorder="0"
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                    ></iframe>
                                </div>

                                <div className="row">
                                    <div className="col-md-3">
                                        <span className="mt-5 f-12">SUBSCRIBE TO LENSES</span>
                                        <br />
                                        <div
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            ref={(el) => (this.instance = el)}
                                            className="g-ytsubscribe"
                                            title="Subscribe to Lenses"
                                            data-channelid="UCWHoDtkhWPswP5KIY6mq7RQ"
                                            data-layout="default"
                                            data-count="hidden"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="p-3 bg-white rounded border shadow-sm">
                                    <p className="video-summary">{video.summary.summary}</p>
                                </div>
                                <span className="f-12 pl-1">
                                    {date.toLocaleDateString('en-US', dateOptions)}
                                </span>
                                <br />

                                <div className="mt-2">
                                    <span className="f-12">SHARE THIS VIDEO</span>
                                    <div
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        ref={(el) => (this.instance2 = el)}
                                        className="a2a_kit a2a_kit_size_32 a2a_default_style"
                                    >
                                        <a className="a2a_button_linkedin a2a_counter px-2"></a>
                                        <a className="a2a_button_twitter a2a_counter px-2"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Spacing />
                <section className="mobile-margin-bottom mobile-margin-top">
                    <div className="container-1 bg-dark rounded-1 p-5">
                        <div className="d-flex flex-wrap">
                            <div className="col-md-12 col-12 text-center roll-button">
                                <p className="mb-5 paragraph-title text-white">
                                    Get your DataOps workspace for Apache Kafka:
                                </p>
                                <a
                                    className="bg-white primary-text"
                                    href="//portal.lenses.io/register"
                                >
                                    <span data-hover="Let's go"> Let&apos;s go</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <Spacing />
            </Layout>
        )
    }
}

export default video

export const videoQuery = graphql`
    query VideoBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        contentfulVideo(slug: { eq: $slug }) {
            date
            description
            title
            seoTitle
            summary {
                summary
            }
            oGimage {
                fluid(quality: 100) {
                    src
                }
            }
            videoId
            keywords {
                keywords
            }
        }
    }
`
